import * as AdminConnectionsTab from './AdminConnectionsTab.svelte';
import * as AdminUsersTab from './AdminUsersTab.svelte';
import * as AdminRolesTab from './AdminRolesTab.svelte';
import * as AdminAuthTab from './AdminAuthTab.svelte';
import * as CompareModelTab from './CompareModelTab.svelte';
import * as ModelTransformTab from './ModelTransformTab.svelte';
import * as QueryDesignTab from './QueryDesignTab.svelte';
import * as PerspectiveTab from './PerspectiveTab.svelte';

export default {
  AdminConnectionsTab,
  AdminUsersTab,
  AdminRolesTab,
  AdminAuthTab,
  CompareModelTab,
  ModelTransformTab,
  QueryDesignTab,
  PerspectiveTab,
};
