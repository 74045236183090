<script lang="ts">
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import FormCheckboxField from '../forms/FormCheckboxField.svelte';

  import FormProviderCore from '../forms/FormProviderCore.svelte';
  import FormSelectField from '../forms/FormSelectField.svelte';
  import FormTextField from '../forms/FormTextField.svelte';

  export let values;
  export let onClose;
</script>

<FormProviderCore {values}>
  <div class="wrapper">
    <FormStyledButton value="Close" on:click={onClose} />
    <div class="scroller">
      <FormCheckboxField name="hideRowsWithoutOperations" label="Hide rows without operations" />

      <FormCheckboxField name="ignoreCase" label="Ignore case" />
      <FormCheckboxField name="ignoreSchema" label="Ignore schema" />
      <FormCheckboxField name="ignoreConstraintNames" label="Ignore constraint names" />

      <FormSelectField
        name="dropTableModel"
        label="Allow drop tables"
        options={[
          {
            value: '',
            label: 'Drop tables if missing',
          },
          {
            value: 'denyDrop',
            label: "Don't drop tables",
          },
          {
            value: 'markAsDeleted',
            label: 'Mark tables as deleted',
          },
        ]}
        isNative
      />

      <div class="ml-2">
        <FormCheckboxField name="allowTableRecreate" label="Allow recreate table" />
      </div>

      <FormSelectField
        name="dropColumnModel"
        label="Allow drop columns"
        options={[
          {
            value: '',
            label: 'Drop columns if missing',
          },
          {
            value: 'denyDrop',
            label: "Don't drop columns",
          },
          {
            value: 'markAsDeleted',
            label: 'Mark columns as deleted',
          },
        ]}
        isNative
      />

      <FormSelectField
        name="dropSqlObjectModel"
        label="Allow drop view, procedure, etc."
        options={[
          {
            value: '',
            label: 'Drop object if missing',
          },
          {
            value: 'denyDrop',
            label: "Don't drop object",
          },
          {
            value: 'markAsDeleted',
            label: 'Mark object as deleted',
          },
        ]}
        isNative
      />

      <FormCheckboxField name="noDropConstraint" label="Don't drop constraints" />

      <FormTextField
        name="deletedPrefixValue"
        label="Deleted prefix"
        placeholder="_deleted_"
        disabled={!$values?.deleteTablesWithPrefix &&
          !$values?.deleteColumnsWithPrefix &&
          !$values?.deleteSqlObjectsWithPrefix}
      />

      <FormCheckboxField name="ignoreForeignKeyActions" label="Ignore foreign key actions" />
      <FormCheckboxField name="ignoreDataTypes" label="Ignore data types" />

      <FormTextField name="ignoreNameRegex" label="Ignore names matching regex" />
    </div>
  </div>
</FormProviderCore>

<style>
  .scroller {
    overflow-y: auto;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
</style>
