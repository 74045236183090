<script lang="ts">
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import FormProvider from '../forms/FormProvider.svelte';
  import _ from 'lodash';

  import ModalBase from './ModalBase.svelte';
  import { closeCurrentModal } from './modalTools';
  import { getDatabaseInfo, useDatabaseInfo, useDatabaseStatus } from '../utility/metadataLoaders';
  import LoadingInfo from '../elements/LoadingInfo.svelte';
  import FormSelectField from '../forms/FormSelectField.svelte';
  import { writable } from 'svelte/store';
  import ErrorInfo from '../elements/ErrorInfo.svelte';
  import FormProviderCore from '../forms/FormProviderCore.svelte';
  import FontIcon from '../icons/FontIcon.svelte';
  import FormConnectionSelect from '../impexp/FormConnectionSelect.svelte';
  import FormDatabaseSelect from '../impexp/FormDatabaseSelect.svelte';
  import { onMount } from 'svelte';
  import { apiCall } from '../utility/api';
  import getElectron from '../utility/getElectron';
  import { destroy_block } from 'svelte/internal';
  import FormElectronFileSelector from '../forms/FormElectronFileSelector.svelte';
  import FormArchiveFolderSelect from '../forms/FormArchiveFolderSelect.svelte';
  import { currentArchive, selectedWidget } from '../stores';
  import { showSnackbarSuccess } from '../utility/snackbar';
  import { openJsonDocument } from '../tabs/JsonTab.svelte';
  import { downloadFromApi } from '../utility/exportFileTools';
  import FormSchemaSelect from '../impexp/FormSchemaSelect.svelte';

  export let conid;
  export let database;

  const values = writable({
    destination: 'dataArchive',
    conid,
    database,
    outputFolder: '',
    archiveFolder: $currentArchive,
    outputFile: '',
    schema: '__all',
  });

  let isRunning = false;

  $: status = useDatabaseStatus({ conid: $values.conid, database: $values.database });
  $: dbinfo = useDatabaseInfo({ conid: $values.conid, database: $values.database });

  onMount(() => {
    apiCall('database-connections/ping', { conid, database });
    const handle = setInterval(() => {
      if ($values.conid && $values.database) {
        apiCall('database-connections/ping', { conid: $values.conid, database: $values.database });
      }
      values.update(x => ({ ...x }));
    }, 20 * 1000);
    return () => clearInterval(handle);
  });

  const electron = getElectron();

  async function handleSubmit() {
    isRunning = true;
    switch ($values.destination) {
      case 'dataArchive':
        await apiCall('database-connections/export-model', {
          conid: $values.conid,
          database: $values.database,
          schema: $values.schema == '__all' ? null : $values.schema,
          outputFolder: 'archive:' + $values.archiveFolder,
        });

        currentArchive.set($values.archiveFolder);
        selectedWidget.set('archive');
        showSnackbarSuccess(`Saved to archive ${$values.archiveFolder}`);
        break;

      case 'yamlFolder':
        await apiCall('database-connections/export-model', {
          conid: $values.conid,
          database: $values.database,
          schema: $values.schema == '__all' ? null : $values.schema,
          outputFolder: $values.outputFolder,
        });

        showSnackbarSuccess(`Saved to folder ${$values.outputFolder}`);
        break;

      case 'sqlFolder':
        await apiCall('database-connections/export-model-sql', {
          conid: $values.conid,
          database: $values.database,
          schema: $values.schema == '__all' ? null : $values.schema,
          outputFolder: $values.outputFolder,
        });

        showSnackbarSuccess(`Saved to folder ${$values.outputFolder}`);
        break;

      // case 'yamlFolder':
      // case 'sqlFolder':
      //   values.update(x => ({ ...x, outputFolder: x.outputFolder || 'default' }));
      //   break;

      case 'jsonTab':
        const db = await getDatabaseInfo({
          conid: $values.conid,
          database: $values.database,
        });
        openJsonDocument(db, database);
        break;

      case 'sqlFile':
        const electron = getElectron();

        let filePath = $values.outputFile;
        let pureFileName;

        if (!electron) {
          const resp = await apiCall('files/generate-uploads-file', { extension: 'sql' });
          filePath = resp.filePath;
          pureFileName = resp.fileName;
        }

        await apiCall('database-connections/export-model-sql', {
          conid: $values.conid,
          database: $values.database,
          outputFile: filePath,
          schema: $values.schema == '__all' ? null : $values.schema,
        });

        if (electron) {
          showSnackbarSuccess(`Saved to file ${$values.outputFile}`);
        } else {
          downloadFromApi(`uploads/get?file=${pureFileName}`, pureFileName);
        }
    }

    // const resp = await apiCall('database-connections/export-model', {
    //   conid: connection._id,
    //   database: name,
    // });
    // currentArchive.set(resp.archiveFolder);
    // selectedWidget.set('archive');
    // visibleWidgetSideBar.set(true);

    closeCurrentModal();
  }
</script>

<FormProviderCore {values}>
  <ModalBase {...$$restProps}>
    <div slot="header">Export database model</div>

    {#if isRunning}
      <LoadingInfo message="Exporting database model" />
    {:else}
      <FormConnectionSelect label="Connection" name="conid" direction="source" isNative />
      <FormDatabaseSelect label="Database" name="database" direction="source" conidName="conid" isNative />
      <FormSchemaSelect
        label="Schema"
        name="schema"
        conidName="conid"
        databaseName="database"
        allowAllSchemas={$values.destination != 'yamlFolder'}
        isNative
      />

      <!-- <div>Connection: <strong>{getConnectionLabel($coninfo)}</strong></div>
    <div>Database: <strong>{database}</strong></div> -->

      {#if $status && $status.name == 'error'}
        <ErrorInfo message={$status?.feedback?.errorMessage} />
      {:else if ($status && ($status.name == 'pending' || $status.name == 'checkStructure' || $status.name == 'loadStructure') && $dbinfo) || !$dbinfo}
        <LoadingInfo message={$status?.feedback?.analysingMessage || 'Loading database structure'} />
      {:else}
        <FormSelectField
          label="Destination"
          name="destination"
          isNative
          options={[
            { label: 'Data archive (YAML + SQL)', value: 'dataArchive' },
            electron && { label: 'Folder (YAML + SQL)', value: 'yamlFolder' },
            electron && { label: 'Folder with SQL files', value: 'sqlFolder' },
            { label: 'JSON file', value: 'jsonFile' },
            { label: 'Open JSON in new tab', value: 'jsonTab' },
            { label: 'SQL file', value: 'sqlFile' },
          ]}
        />

        {#if $values.destination == 'dataArchive'}
          <div class="infobox">
            <FontIcon icon="img info" padRight />
            Data archive is folder with YAML files (tables) and SQL files (views, procedures) stored in dbgate internal storage.
          </div>
        {/if}

        {#if $values.destination == 'yamlFolder'}
          <div class="infobox">
            <FontIcon icon="img info" padRight />
            Folder with YAML files (tables) and SQL files (views, procedures)
          </div>
        {/if}

        {#if $values.destination == 'dataArchive'}
          <FormArchiveFolderSelect
            label="Archive folder"
            name="archiveFolder"
            additionalFolders={_.compact([$values.archiveFolder])}
            isNative
          />
        {/if}

        {#if electron && ($values.destination == 'yamlFolder' || $values.destination == 'sqlFolder')}
          <FormElectronFileSelector
            dialogProperties={['openDirectory', 'createDirectory']}
            label="Output folder"
            name="outputFolder"
          />
        {/if}

        {#if electron && $values.destination == 'sqlFile'}
          <FormElectronFileSelector
            isSaveDialog
            label="Output file"
            name="outputFile"
            dialogFilters={[
              { name: 'SQL Files', extensions: ['sql'] },
              { name: 'All Files', extensions: ['*'] },
            ]}
          />
        {/if}
      {/if}
    {/if}

    <div slot="footer">
      <FormStyledButton
        value="Export"
        disabled={isRunning ||
          $status?.name != 'ok' ||
          (($values.destination == 'yamlFolder' || $values.destination == 'sqlFolder') && !$values.outputFolder) ||
          ($values.destination == 'sqlFile' && electron && !$values.outputFile)}
        on:click={handleSubmit}
      />
      <FormStyledButton type="button" value="Cancel" on:click={closeCurrentModal} />
    </div>
  </ModalBase>
</FormProviderCore>

<style>
  .infobox {
    margin-left: var(--dim-large-form-margin);
  }
</style>
