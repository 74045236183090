<script lang="ts">
  import { changeSetInsertDocuments, deleteChangeSetRows } from 'dbgate-datalib';
  import { apiCall } from '../utility/api';
  import PermissionCheckBox from './PermissionCheckBox.svelte';

  export let changeSetState;
  export let dispatchChangeSet;

  export let permissionsTableName;
  export let filterValue;
  export let filterColumn;

  export let saveKey;

  export let basePermissions = [];

  let loadedPermissions = [];

  async function loadPermissions() {
    loadedPermissions = await apiCall('storage/read-permissions', {
      tableName: permissionsTableName,
      filterColumn,
      filterValue,
    });
  }

  $: {
    filterColumn;
    filterValue;
    permissionsTableName;
    saveKey;
    loadPermissions();
  }

  // $: console.log('changeSetState.value', changeSetState.value);
  // $: console.log('loadedPermissions', loadedPermissions);

  $: insertedPermissions = changeSetState.value.inserts
    .filter(x => x.pureName == permissionsTableName && x.fields[filterColumn] == filterValue)
    .map(x => x.fields.permission);

  $: deletedPermissions = changeSetState.value.deletes
    .filter(x => x.pureName == permissionsTableName && x.condition[filterColumn] == filterValue)
    .map(x => x.condition.permission);

  // export async function save() {
  //   if (editedPermissions) {
  //     await apiCall('storage/write-permissions', {
  //       tableName: permissionsTableName,
  //       filterColumn,
  //       filterValue,
  //       permissions: editedPermissions,
  //     });
  //   }
  // }
  function cleanChangeSet(changeSet, permission) {
    changeSet = {
      ...changeSet,
      inserts: changeSet.inserts.filter(
        x =>
          x.pureName != permissionsTableName ||
          (x.fields.permission != permission && x.fields.permission != '~' + permission) ||
          x.fields[filterColumn] != filterValue
      ),
    };

    if (loadedPermissions.includes(permission)) {
      changeSet = deleteChangeSetRows(changeSet, {
        pureName: permissionsTableName,
        schemaName: null,
        condition: {
          [filterColumn]: filterValue,
          permission,
        },
      });
    }
    if (loadedPermissions.includes('~' + permission)) {
      changeSet = deleteChangeSetRows(changeSet, {
        pureName: permissionsTableName,
        schemaName: null,
        condition: {
          [filterColumn]: filterValue,
          permission: '~' + permission,
        },
      });
    }
    return changeSet;
  }

  function handleSetPermission(permission, value) {
    // console.log('handleSetPermission', permission, value);
    if (value == null) {
      let changeSet = changeSetState.value;
      changeSet = cleanChangeSet(changeSet, permission);

      dispatchChangeSet({
        type: 'set',
        value: changeSet,
      });

      // if (deletedPermissions.includes(permission)) {
      //   return;
      // }

      // if (insertedPermissions.includes(permission)) {
      //   dispatchChangeSet({
      //     type: 'set',
      //     value: {
      //       ...changeSetState.value,
      //       inserts: changeSetState.value.inserts.filter(
      //         x =>
      //           x.pureName != permissionsTableName ||
      //           x.fields.permission != permission ||
      //           x.fields[filterColumn] != filterValue
      //       ),
      //     },
      //   });
      // } else {
      //   dispatchChangeSet({
      //     type: 'set',
      //     value: deleteChangeSetRows(changeSetState.value, {
      //       pureName: permissionsTableName,
      //       schemaName: null,
      //       condition: {
      //         [filterColumn]: filterValue,
      //         permission,
      //       },
      //     }),
      //   });
      // }
    } else {
      let changeSet = changeSetState.value;
      changeSet = cleanChangeSet(changeSet, permission);

      changeSet = changeSetInsertDocuments(
        changeSet,
        [
          {
            [filterColumn]: filterValue,
            permission: value ? permission : '~' + permission,
          },
        ],
        { pureName: permissionsTableName },
        [filterColumn, 'permission']
      );

      dispatchChangeSet({
        type: 'set',
        value: changeSet,
      });

      // const prefixedPermission = value ? permission : `~${permission}`;

      // if (insertedPermissions.includes(prefixedPermission)) {
      //   return;
      // }

      // if (deletedPermissions.includes(prefixedPermission)) {
      //   dispatchChangeSet({
      //     type: 'set',
      //     value: {
      //       ...changeSetState.value,
      //       deletes: changeSetState.value.deletes.filter(
      //         x =>
      //           x.pureName != permissionsTableName ||
      //           x.condition.permission != prefixedPermission ||
      //           x.condition[filterColumn] != filterValue
      //       ),
      //     },
      //   });
      // } else {
      //   dispatchChangeSet({
      //     type: 'set',
      //     value: changeSetInsertDocuments(
      //       changeSetState.value,
      //       [
      //         {
      //           [filterColumn]: filterValue,
      //           permission: prefixedPermission,
      //         },
      //       ],
      //       { pureName: permissionsTableName },
      //       [filterColumn, 'permission']
      //     ),
      //   });
      // }
    }

    // if (!editedPermissions) {
    //     editedPermissions=loadedPermissions
    // }
  }

  // $: console.log('CHANGESET', changeSetState.value);
  // $: console.log('insertedPermissions', insertedPermissions);
  // $: console.log('deletedPermissions', deletedPermissions);
  // $: console.log('loadedPermissions', loadedPermissions);
  // $: console.log('basePermissions', basePermissions);
  // $: console.log('saveKey', saveKey);
</script>

<div class="wrapper">
  <PermissionCheckBox
    label="All permissions"
    permission="*"
    {deletedPermissions}
    {insertedPermissions}
    {loadedPermissions}
    {basePermissions}
    onSetPermission={handleSetPermission}
  />

  <div class="ml-4">
    <PermissionCheckBox
      label="All connections"
      permission="all-connections"
      {deletedPermissions}
      {insertedPermissions}
      {loadedPermissions}
      {basePermissions}
      onSetPermission={handleSetPermission}
    />

    <PermissionCheckBox
      label="Internal storage"
      permission="internal-storage"
      {deletedPermissions}
      {insertedPermissions}
      {loadedPermissions}
      {basePermissions}
      onSetPermission={handleSetPermission}
    />

    <PermissionCheckBox
      label="Change settings"
      permission="settings/change"
      {deletedPermissions}
      {insertedPermissions}
      {loadedPermissions}
      {basePermissions}
      onSetPermission={handleSetPermission}
    />

    <PermissionCheckBox
      label="Install plugins"
      permission="plugins/install"
      {deletedPermissions}
      {insertedPermissions}
      {loadedPermissions}
      {basePermissions}
      onSetPermission={handleSetPermission}
    />

    <PermissionCheckBox
      label="Administration"
      permission="admin/*"
      {deletedPermissions}
      {insertedPermissions}
      {loadedPermissions}
      {basePermissions}
      onSetPermission={handleSetPermission}
    />

    <div class="ml-4">
      <PermissionCheckBox
        label="Authentication"
        permission="admin/auth"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
      <PermissionCheckBox
        label="Connections"
        permission="admin/connections"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
      <PermissionCheckBox
        label="Users"
        permission="admin/users"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
      <PermissionCheckBox
        label="Roles"
        permission="admin/roles"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
      <PermissionCheckBox
        label="Set license"
        permission="admin/license"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
    </div>

    <PermissionCheckBox
      label="Database operations"
      permission="dbops/*"
      {deletedPermissions}
      {insertedPermissions}
      {loadedPermissions}
      {basePermissions}
      onSetPermission={handleSetPermission}
    />
    <div class="ml-4">
      <PermissionCheckBox
        label="SQL templates"
        permission="dbops/sql-template/*"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="SQL generator"
        permission="dbops/sql-generator"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="SQL dump"
        permission="dbops/sql-dump/*"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
      <div class="ml-4">
        <PermissionCheckBox
          label="Import SQL dump"
          permission="dbops/sql-dump/import"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />

        <PermissionCheckBox
          label="Export SQL dump"
          permission="dbops/sql-dump/export"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />
      </div>

      <PermissionCheckBox
        label="Run SQL query"
        permission="dbops/query"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Import data"
        permission="dbops/import"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Export data"
        permission="dbops/export"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="View charts"
        permission="dbops/charts"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Database profiler"
        permission="dbops/profiler"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Create database"
        permission="dbops/createdb"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Drop database"
        permission="dbops/dropdb"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Database model operations"
        permission="dbops/model/*"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <div class="ml-4">
        <PermissionCheckBox
          label="View database model (diagrams, JSON)"
          permission="dbops/model/view"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />

        <PermissionCheckBox
          label="Edit database model (create/change tables, views, etc.)"
          permission="dbops/model/edit"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />

        <PermissionCheckBox
          label="Compare database models"
          permission="dbops/model/compare"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />
      </div>

      <PermissionCheckBox
        label="Table operations"
        permission="dbops/table/*"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
      <div class="ml-4">
        <PermissionCheckBox
          label="Rename table"
          permission="dbops/table/rename"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />
        <PermissionCheckBox
          label="Truncate table"
          permission="dbops/table/truncate"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />
        <PermissionCheckBox
          label="Create table backup"
          permission="dbops/table/backup"
          {deletedPermissions}
          {insertedPermissions}
          {loadedPermissions}
          {basePermissions}
          onSetPermission={handleSetPermission}
        />
      </div>
    </div>

    <PermissionCheckBox
      label="Widgets"
      permission="widgets/*"
      {deletedPermissions}
      {insertedPermissions}
      {loadedPermissions}
      {basePermissions}
      onSetPermission={handleSetPermission}
    />

    <div class="ml-4">
      <PermissionCheckBox
        label="Database widget"
        permission="widgets/database"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="File widget"
        permission="widgets/file"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="History widget"
        permission="widgets/history"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Archive widget"
        permission="widgets/archive"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Plugins widget"
        permission="widgets/plugins"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Cell data widget"
        permission="widgets/cell-data"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Application widget"
        permission="widgets/app"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />

      <PermissionCheckBox
        label="Administration widget"
        permission="widgets/admin"
        {deletedPermissions}
        {insertedPermissions}
        {loadedPermissions}
        {basePermissions}
        onSetPermission={handleSetPermission}
      />
    </div>
  </div>
</div>

<style>
  .wrapper {
    overflow-y: scroll;
    flex: 1;
  }
</style>
