<script lang="ts" context="module">
  const getCurrentEditor = () => getActiveComponent('ModelTransformTab');

  registerFileCommands({
    idPrefix: 'modtran',
    category: 'Shell',
    getCurrentEditor,
    folder: 'modtrans',
    format: 'text',
    fileExtension: 'modtran',

    toggleComment: true,
    findReplace: true,
  });
</script>

<script lang="ts">
  import { getContext } from 'svelte';
  import ToolStripContainer from '../buttons/ToolStripContainer.svelte';
  import ToolStripSaveButton from '../buttons/ToolStripSaveButton.svelte';

  import invalidateCommands from '../commands/invalidateCommands';
  import { registerFileCommands } from '../commands/stdCommands';

  import AceEditor from '../query/AceEditor.svelte';
  import useEditorData from '../query/useEditorData';
  import { changeTab } from '../utility/common';
  import createActivator, { getActiveComponent } from '../utility/createActivator';

  export let tabid;

  const tabVisible: any = getContext('tabVisible');

  export const activator = createActivator('ModelTransformTab', false);

  let busy = false;

  let domEditor;
  let domToolStrip;

  $: {
    busy;
    invalidateCommands();
  }

  $: if ($tabVisible && domEditor) {
    domEditor?.getEditor()?.focus();
  }

  $: {
    changeTab(tabid, tab => ({ ...tab, busy }));
  }

  export function getData() {
    return $editorState.value || '';
  }

  export function toggleComment() {
    domEditor.getEditor().execCommand('togglecomment');
  }

  export function find() {
    domEditor.getEditor().execCommand('find');
  }

  export function replace() {
    domEditor.getEditor().execCommand('replace');
  }

  const { editorState, setEditorData } = useEditorData({ tabid });

  function createMenu() {
    return [
      { command: 'modtran.toggleComment' },
      { divider: true },
      { command: 'modtran.save' },
      { command: 'modtran.saveAs' },
      { command: 'modtran.copyNodeScript' },
      { divider: true },
      { command: 'modtran.find' },
      { command: 'modtran.replace' },
    ];
  }
</script>

<ToolStripContainer bind:this={domToolStrip}>
  <AceEditor
    value={$editorState.value || ''}
    menu={createMenu()}
    on:input={e => setEditorData(e.detail)}
    on:focus={() => {
      activator.activate();
      domToolStrip?.activate();
      invalidateCommands();
    }}
    bind:this={domEditor}
    mode="json"
  />
  <svelte:fragment slot="toolstrip">
    <ToolStripSaveButton idPrefix="modtran" />
  </svelte:fragment>
</ToolStripContainer>
